import React from "react";

import { setTitle } from "../../utils/title";
import Layout from "./Layout";
import {
  accountTitle,
  HELPSCOUT_BEACON_TYPES,
  useHelpscoutBeacon,
} from "./utils";

import { EmptyState } from "../Common/EmptyState";
import { spacing } from "../../stitches.config";
import { buildLink } from "common/routing";
import { useHistory } from "react-router";

const NoPropertyPage = () => {
  const history = useHistory();
  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);

  setTitle(`No Property Found | ${accountTitle()}`);

  return (
    <Layout>
      <div style={{ marginTop: spacing["3xl"].value }}>
        <EmptyState
          body="Please return to the homepage or request help."
          title="No information for this property"
          icon="missing-location"
          primaryButtonProps={{
            label: "Return home",
            onClick: () => history.push("/"),
          }}
          buttonLinkProps={{
            label: "Get help",
            onClick: () => history.push(buildLink("getHelp")),
          }}
        />
      </div>
    </Layout>
  );
};

export default NoPropertyPage;
