import React from "react";
import { setTitle } from "../../utils/title";
import Logo from "../../images/logo-white.svg";

import { HELPSCOUT_BEACON_TYPES, useHelpscoutBeacon } from "./utils";
import { spacing } from "../../stitches.config";
import { EmptyState } from "../Common/EmptyState";
import { buildLink } from "common/routing";
import { useHistory } from "react-router";
import { Page } from "./__styles__/Layout";
import { StyledHeader } from "./__styles__/UnavailablePage";

const UnavailablePage = () => {
  const history = useHistory();
  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);
  setTitle("Page Unavailable");

  return (
    <Page>
      <Header />
      <div style={{ marginTop: spacing["3xl"].value }}>
        <EmptyState
          body="Please contact us if you have any questions."
          title="This page is unavailable"
          icon="missing-location"
          primaryButtonProps={{
            label: "Contact us",
            onClick: () => history.push(buildLink("getHelp")),
          }}
        />
      </div>
    </Page>
  );
};

const Header = () => {
  return (
    <StyledHeader>
      <div>
        <img src={Logo} />
      </div>
    </StyledHeader>
  );
};

export default UnavailablePage;
